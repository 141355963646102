import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    async_Speaker_GET_Articles,
    on_Notice_hide,
    reselect_Speaker_GET_Report_condition,
} from "controllers/articles/redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import KDWSnackbar from "components/KDWSnackbar/KDWSnackbar";
import Loader from "components/Loader/Loader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InboxIcon from "@material-ui/icons/Inbox";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {Alert} from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import {useStyles} from "../../../token/form/FormToken/FormToken";
import __translate, {__getLocaleGreenLogo} from "helper/locale/locale";
import {reselectConstants} from "../../../constants-route/redux-store/constants/constants";
import __locale, {__localeStorage} from "../../../../helper/location/location";

export const STATE_DRAFT = 0;
export const STATE_SUBMITTED = 1;
export const STATE_WORK = 2;
export const STATE_REJECTED = 4;
export const STATE_APPROVED = 8;
export const STATE_EDIT = 12;

const SpeakerReport: React.FC = () => {
    const routeParams = useParams<{ ru: string; report: string }>();
    const reduxDispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{ ru: string }>();
    const classes = useStyles();
    const condition = useSelector(reselect_Speaker_GET_Report_condition);
    const constants = useSelector(reselectConstants);

    React.useEffect(() => {
        if (condition.inProgress) return;
        reduxDispatch(
            async_Speaker_GET_Articles(Number(routeParams.report), null as any, result => {
                if (result?.error?.code === 401) {
                    history.push(`/${__localeStorage()}/login`);
                }
            }),
        );
    }, []);

    if (condition.inProgress) {
        return <Loader />;
    }
    let report = null;
    if (condition.response.error.code === 200) {
        report = condition.response.data?.order?.report || {};
    }
    return (
        <React.Fragment>
            <KDWSnackbar
                isOpen={condition.notice.visibility}
                severity={condition.notice.severity}
                handleOpen={() => {
                    reduxDispatch(on_Notice_hide());
                }}
                message={condition.response.error.message}
            />
            <Grid container className={classes.bg} alignItems="center" justify="center">
                <Grid item md={12} lg={6}>
                    <CssBaseline />
                    <Grid container alignItems="center" justify="center">
                        <Grid item md={7}>
                            <div className={classes.paper}>
                                <Grid item xs={12} md={12}>
                                    <Link href="https://kazandigitalweek.com" target="_blank" rel="noopener">
                                        <img
                                            style={{
                                                display: "block",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginBottom: "2em",
                                                marginTop: "1em",
                                                height: 50,
                                            }}
                                            alt="logo"
                                            src={__getLocaleGreenLogo(__locale())}
                                            className={classes.logo}
                                        />
                                    </Link>
                                </Grid>
                                {!report && (
                                    <Container>
                                        <Typography>
                                            {__translate(
                                                "site.request_error",
                                                "Произошла ошибка при обращении к серверу",
                                            )}
                                        </Typography>
                                    </Container>
                                )}
                                {report !== null && (
                                    <Container>
                                        <List component="nav" aria-label="main mailbox folders">
                                            <React.Fragment>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <InboxIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid item direction={"column"}>
                                                                <Typography>
                                                                    {__translate(
                                                                        "signup.report_title",
                                                                        "Название доклада",
                                                                    )}
                                                                    : {report.title}.
                                                                </Typography>
                                                                <Typography>
                                                                    {__translate("profile.status_title", "Статус")}:{" "}
                                                                    {constants && constants.user.report_states[report.state]}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        secondary={
                                                            report.annotation_file ? (
                                                                <a target="_blank" href={report.annotation_file.file}>
                                                                    {report.annotation_file.name}
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )
                                                        }
                                                    />
                                                </ListItem>
                                            </React.Fragment>
                                        </List>
                                    </Container>
                                )}

                                {condition.response &&
                                    condition.response.error &&
                                    condition.response.error.code !== 200 && (
                                        <Container>
                                            <Typography>{__translate("site.error", "Ошибка")}:</Typography>
                                            <Box maxWidth={300}>
                                                <Alert severity="error">{condition.response.error.message}</Alert>
                                            </Box>
                                        </Container>
                                    )}
                                {condition.response &&
                                    condition.response.error &&
                                    condition.response.error.code === 200 && (
                                        <Container>
                                            <Typography>
                                                {__translate(
                                                    "profile.need_pay",
                                                    "Для продолжения участия с докладом вам необходимо оплатить доклад",
                                                )}
                                            </Typography>
                                        </Container>
                                    )}
                                <div dangerouslySetInnerHTML={{ __html: condition.response?.data?.html || "" }} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default SpeakerReport;
