import { LangEnum } from "../../controllers/admin-expo/enums";
import __locale from "../../helper/location/location";

export const SCREEN_SM = 576;
export const SCREEN_MD = 768;
export const SCREEN_LG = 992;
export const SCREEN_XL = 1200;
export const SCREEN_XXL = 1400;

export const SIGN_LINK = [
    {
        desc: "Войти",
        key: "signup.login_btn",
        link: "/" + __locale() + "/login",
    },
    // {
    //     desc: "Регистрация",
    //     key: "signup.title",
    //     link: "/sign-up",
    // },
];

export const SIGN_UP_MEDIA = {
    mass: {
        desc: "Регистрация СМИ",
        key: "signup.isMass",
        link: "/sign-up-media",
    },
};

export const SELECTORS_TO_SCROLL = {
    indexPromo: "indexPromo",
    aboutForum: "aboutForum",
    participants: "participants",
    exposition: "exposition",
    partners: "partners",
    anons: "anons",
    contacts: "contacts",
    events: "events",
};

export const LINKS = [
    {
        descLink: "О форуме",
        key: "site.about_forum",
        link: "site/aboutForum/",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.aboutForum,
    },
    {
        descLink: "О выставке",
        key: "site.about_expo",
        link: "site/expo/",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.exposition,
    },
    {
        descLink: "Участнику",
        key: "site.participants",
        link: "",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.participants,
    },
    {
        descLink: "Мероприятия",
        key: "site.events",
        link: "",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.events,
    },
    {
        descLink: "Партнеры",
        key: "site.partners",
        link: "",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.partners,
    },
    {
        descLink: "Контакты",
        key: "profile.contacts",
        link: "site/contacts/",
        selectorToScroll: "#" + SELECTORS_TO_SCROLL.contacts,
    },
    // { descLink: "Марафон хакатонов", key: "site.kazandigitallegends", link: "https://kazandigitallegends.com/" },
];
export const RESULTS = {
    descLink: "Посмотрите итоги прошлых лет",
    key: "site.results",
    link: "/results/",
    selectorToScroll: "",
    underline: true,
};

export const ADDITIONAL_LINKS = [
    // {
    //     descLink: "Экспоненты",
    //     key: "site.exponents",
    //     link: "site/exponents/",
    //     selectorToScroll: "",
    // },
    // {
    //     descLink: "Все мероприятия",
    //     key: "site.all_events",
    //     link: "site/events",
    //     selectorToScroll: "",
    // },
    { descLink: "Новости", key: "site.news", link: "site/news/", selectorToScroll: "", underline: false },
    RESULTS,
];

export const LANG_OPTIONS = [
    {
        lang: "РУС",
        link: "/ru/",
        value: LangEnum.RU,
    },
    {
        lang: "EN",
        link: "/en/",
        value: LangEnum.EN,
    },
];

export const REG_LINK = "/sign-up";

export const PROMO_INFO = {
    infoMail: {
        desc: "Информационное письмо",
        linkDesc: "Подробнее",
        link: {
            ru: "/upload/iblock/005/info.pdf",
            en: "/upload/iblock/005/info_en.pdf",
            cn: "",
        },
    },
    speakers: {
        desc: "Спикеры",
        linkDesc: "Подробнее",
        link: "/site/speakers/",
    },
};

export const EXPO_PLAN = {
    link: "https://drive.google.com/file/d/12uFmmwLSnyHMonyQ4--o1gBReGwEr9gN/view",
    desc: {
        ru: "Экспо-план",
        en: "Expо-plan",
        cn: "",
    },
};

export const EXPO = {
    ru: (
        <div>
            <p>
                В рамках Форума 2023 года планируется организация масштабной выставки в Павильоне № 2 МВЦ «Казань
                Экспо». Участие в данной выставке - это возможность продемонстрировать свою продукцию широкой аудитории
                специалистов, а также привлечь новых потенциальных заказчиков и партнеров.
            </p>
            <p>
                Форум привлечет более 2500 участников из наиболее известных и узнаваемых компаний, а также влиятельных
                людей из сферы цифровых технологий. На выставке будут работать ведущие российские и зарубежные СМИ.
                Участвуя в выставке, Вы и Ваши проекты окажетесь среди новейших решений и самых передовых технологий,
                открывая для вашей организации рынок будущего.
            </p>
            <p>
                Выставка «Kazan Digital Week - 2023» пройдёт под эгидой Правительства Российской Федерации, первые лица
                государства примут участие в торжественном открытии выставки, а также посетят стенды ключевых
                экспонентов.
            </p>
            <p>Приглашаем Вашу компанию к активному участию в выставке Форума Kazan Digital Week-2023!</p>
        </div>
    ),
    en: (
        <div className="inner-page__main-inner">
            <p>
                Within the framework of the Forum 2023 it is planned to organize a large-scale exhibition in Pavilion No
                2 of the Kazan Expo IEC. Participation in the exhibition is an opportunity to demonstrate your products
                to a wide audience of specialists, to attract new potential customers and partners.
            </p>
            <p>
                The Forum will attract more than 2,500 participants from the most famous and recognizable companies,
                influential people from the digital technology industry. The leading Russian and foreign mass media will
                participate in the exhibition. By participating in the exhibition, you and your projects will be among
                the latest solutions and the most advanced technologies, opening a new era market for your organization.
            </p>
            <p>
                The exhibition "Kazan Digital Week - 2023" will be held under the auspices of the Government of the
                Russian Federation, its top officials will take part in the grand opening of the exhibition, visiting
                the key exhibitors.
            </p>
            <p>We invite your company to actively participate in the Kazan Digital Week-2023 Forum exhibition!</p>
        </div>
    ),
    cn: "",
};

export const PROGRAM_LINKS = [
    {
        link: "/program/",
        descLink: "",
    },
    {
        link: "/program/program.pdf",
        descLink: "Печать",
    },
    {
        link: "/program/program_en.pdf",
        descLink: "Download",
    },
];

export const PUBLICATION_INFO = {
    ru: (
        <>
            <p>По вопросам публикации статей:</p>
            <p>Галиева Сирина Габдулахатовна (язык общения – русский)</p>
            <p>
                <a href="mailto:org@kazandigitalweek.ru">org@kazandigitalweek.ru</a>
            </p>
        </>
    ),
    en: (
        <>
            <p>For articles publishing:</p>
            <p>Sirina Galieva (Russian language)</p>
            <p>
                <a href="mailto:org@kazandigitalweek.ru">org@kazandigitalweek.ru</a>
            </p>
        </>
    ),
    cn: "",
};

export const PUBLICATION_CONTENT = {
    ru: (
        <>
            <table>
                <caption>Требования к оформлению статей</caption>
                <tbody>
                    <tr>
                        <th>
                            <p>Язык публикации статьи</p>
                        </th>
                        <td>
                            <p>Английский или русский</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Общий объем статьи (включая заголовок, аннотация, ключевые слова, текст, литература)</p>
                        </th>
                        <td>
                            <p>
                                Минимальный объем – 9&nbsp;000 знаков с пробелами. Максимальный объем: – не более 23 000
                                знаков с пробелами.
                            </p>
                            <p>При этом&nbsp;объем статьи в листах&nbsp;A4:&nbsp;от 6 до 12&nbsp;</p>
                            <p>
                                (оформление: шрифт TimesNewRoman, 14 кегль обычный интервал между букв, межстрочный
                                интервал min.18;
                            </p>
                            <p>&nbsp;поля 2 см со всех сторон).</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Аннотация и ключевые слова</p>
                        </th>
                        <td>
                            <p>Перед названием статьи указать код УДК.</p>
                            <p>
                                250-300 слов (как правило, это 6-8 предложений), должна содержательно передавать
                                основные результаты на русском&nbsp;и английском языках.&nbsp;
                            </p>
                            <p>
                                Аннотация не должна выделяться курсивом, подчеркиванием и т.п. В аннотации не
                                допускается цитирование.&nbsp;
                            </p>
                            <p>Аббревиатуры должны быть расшифрованы. Аннотация не должна повторять текст статьи.</p>
                            <p>
                                Сразу после аннотации должны быть представлены ключевые слова, которые могут состоять из
                                отдельных слов&nbsp;и словосочетаний на русском и английском языках (до 10 ключевых
                                слов).
                            </p>
                            <p>&nbsp;Курсивом или жирным шрифтом выделять текст не нужно.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Требования к содержанию статьи и ее оформлению</p>
                        </th>
                        <td>
                            <p>
                                После ключевых слов нужно представить текст самой статьи.
                                Текст&nbsp;рекомендуется&nbsp;разбить на подглавы или&nbsp;
                            </p>
                            <p>придерживаться данной логической структуры при написании:</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; введение;</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; методика;</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; основная часть;</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; выводы;</p>
                            <p>
                                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; благодарности (т.е. этот раздел нужен,
                                если необходимо указать, что статья подготовлена в рамках гранта);
                            </p>
                            <p>
                                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; поблагодарить коллег, которые не
                                являются авторами статьи, но при их содействии проводилось исследование и т.п.);
                            </p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; литература</p>
                            <p>Размер шрифта и интервал между строками должен быть одинаковым по всему тексту.</p>
                            <p>
                                Абзацы («красная строка») должны выставляться автоматически, а не с помощью клавиши
                                «пробел».&nbsp;
                            </p>
                            <p>Наличие двойных или тройных пробелов не допустимо.</p>
                            <p>При первом употреблении аббревиатур обязательно указывать их расшифровку.</p>
                            <p>Прямая речь, цитирование должно быть оформлено с использованием кавычек «…..».</p>
                            <p>&nbsp;НЕ ДОПУСКАЕТСЯ использование кавычек такого формата:&nbsp;“…..”.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Требования к таблицам, рисункам и формулам</p>
                        </th>
                        <td>
                            <p>Перед таблицами, рисунками и формулами должны быть ссылки на них.</p>
                            <p>
                                Текст может содержать таблицы, названия к которым должны приводиться над таблицей с
                                выравниванием по центру.
                            </p>
                            <p>Текст в таблицах: интервал одинарный, шрифт 12 Times New Roman.</p>
                            <p>
                                Все составляющие формул должны быть оформлены в макросе «Microsoft&nbsp; Equation»
                                (программа Word).
                            </p>
                            <p>
                                Графические рисунки должны быть хорошего качества. Надписи в рисунках должны четко
                                отображаться.&nbsp;
                            </p>
                            <p>
                                В диаграммах должны быть указаны цифровые значения и названия столбцов и должны быть
                                понятными.
                            </p>
                            <p>Подписи к рисункам приводятся под рисунком с выравниванием по центру.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Требования к источникам, используемым в статье</p>
                        </th>
                        <td>
                            <p>
                                В списке литературы должно быть не менее 10 и не более
                                <br />
                                15 источников. Как минимум, 2-3 источника – это работы, опубликованные за последние 5-10
                                лет.&nbsp;
                            </p>
                            <p>
                                Рекомендуется, чтобы не менее 50% источников, включенных в библиографический список,
                                были работы,&nbsp;
                            </p>
                            <p>опубликованные на английском языке.</p>
                            <p>Допускается не более трех ссылок на собственные публикации.</p>
                            <p>Использование подстрочных ссылок недопустимо.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Оформление ссылок и списка литературы</p>
                        </th>
                        <td>
                            <p>– Ссылки в тексте оформляются квадратными скобками.</p>
                            <p>– Источники указываются в порядке цитирования в тексте.</p>
                            <p>– На все источники из списка литературы должны быть ссылки в тексте.</p>
                            <p>
                                – Список литературы оформляется в соответствии с образцом (см. раздел «Образец
                                оформления литературы»).
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>
                                Образец оформления литературы (если статья предоставляется на русском языке для
                                последующего перевода, ссылки можно указывать в соответствии с ГОСТ Р 7.0.100-2018
                            </p>
                        </th>
                        <td>
                            <p>Статья из журнала, материалы конференции (печатный)</p>
                            <p>
                                Бахвалов, Ю. Н. Обучение и оценка обобщающей способности методов многомерной
                                интерполяции / Ю. Н. Бахвалов, И. В.&nbsp;Копылов // Компьютерные исследования и
                                моделирование. – 2015. – Том 7. – № 5. – С. 1023–1031.
                            </p>
                            <p>Статья из журнала (электронный)</p>
                            <p>
                                Конторович, А. Э. Прогноз глобального энергообеспечения : методология, количественные
                                оценки, практические выводы /&nbsp;А. Э. Конторович, А. Г. Коржубаев, Л. В.&nbsp;Эдер. –
                                DOI&nbsp;10.21267/IN.2016.6.2294. – Текст: электронный // Минеральные ресурсы
                                России.&nbsp;Экономика и управление. – 2006. – № 5.&nbsp; – С. 6–15. –URL:&nbsp;
                                <a href="http://www.vipstd.ru/gim/contant/view/90/278/">
                                    http://www.vipstd.ru/gim/contant/view/90/278/
                                </a>
                                . (дата обращения: 22.05.2020).
                            </p>
                            <p>Книга</p>
                            <p>
                                Семенов, В. В. Философия : итог тысячелетий. Философская психология / В. В.
                                Семенов;&nbsp;Российская академия наук, Пущинский научный центр, Институт биофизики
                                клетки, Академия проблем сохранения жизни. &nbsp;Пущино : ПНЦ РАН, 2000. – 64 с.
                            </p>
                            <p>
                                Миокардиты : современные подходы к диагностике и лечению : монография / В. Н. Быков [и
                                др.];&nbsp;Ответственный редактор В. Н. Сухов;&nbsp;Министерство образования Российской
                                Федерации, Санкт-Петербургская государственная лесотехническая академия.&nbsp;– [2-е
                                изд., перераб. и доп.]. &nbsp;Санкт-Петербург : СПбЛТА, 2001. – 231 с.
                            </p>
                            <p>Диссертация</p>
                            <p>
                                Миндиашвили, Д. Г. Управление процессом формирования статокинетической устойчивости
                                квалифицированных борцов :&nbsp;специальность 13.00.04 «Теория и методика физического
                                воспитания, спортивной тренировки, оздоровительной и адаптивной&nbsp;физической
                                культуры» :диссертация на соискание ученой степени кандидата педагогических наук /
                                Миндиашвили Дмитрий Георгиевич;&nbsp;Красноярский государственный педагогический
                                университет. – Красноярск, 1992. – 24 с.
                            </p>
                            <p>Интернет-ресурс</p>
                            <p>
                                Правительство Российской Федерации : официальный сайт. – URL:&nbsp;
                                <a href="http://www.government.ru">www.government.ru</a>&nbsp;(дата обращения:
                                19.02.2018). – Текст: электронный.
                            </p>
                            <p>
                                Обратите внимание, гиперссылка должна быть «неактивная», http:// – этот элемент не
                                указывать.
                            </p>
                            <p>Официальный источник</p>
                            <p>
                                Российская Федерация. Законы. О воинской обязанности и военной службе : Федеральный
                                закон № 142 от 6 марта 1998 года (в редакции от 8.01.2020). – URL:&nbsp;
                                <a href="http://www.docs.cntd.ru/document/901704754">
                                    www.docs.cntd.ru/document/901704754
                                </a>
                                &nbsp;(дата обращения: 22.05.2020). – Текст: электронный.
                            </p>
                            <p>
                                ГОСТ Р 57564–2017. Организация и проведение работ по международной стандартизации в
                                Российской Федерации = Organization and implementation of activity on international
                                standardization in Russian Federation : национальный стандарт Российской Федерации:
                                издание официальное : утвержден и введен в действие Приказом Федерального агентства по
                                техническому регулированию и метрологии от 28 июля 2017 г. № 767-ст : введен впервые :
                                дата введения 2017-12-01 / разработан Всероссийским научно-исследовательским институтом
                                стандартизации и сертификации в машиностроении (ВНИИНМАШ). – Москва : Стандартинформ,
                                2017. – 43 с.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Сведения об авторе(ах)</p>
                        </th>
                        <td>
                            <p>После названия научной статьи указывается:</p>
                            <p>– ФИО автора полностью (на русском и английском языках);</p>
                            <p>
                                – ученая степень и звание, должность, место работы (на русском и английском языках);
                                указываем ORCID;
                            </p>
                            <p>– почтовый адрес организации, включая улицу, дом, индекс населенного пункта;</p>
                            <p>– контактный телефон автора;</p>
                            <p>В самом письме указываете е-mail и контактный телефон для связи.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    ),
    en: (
        <>
            <table>
                <caption>Articles requirements</caption>
                <tbody>
                    <tr>
                        <th>
                            <p>Article publication language</p>
                        </th>
                        <td>
                            <p>English or Russian</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>
                                The total volume of the article (including title, abstract, keywords, text, literature)
                            </p>
                        </th>
                        <td>
                            <p>
                                The minimum length is 9,000 characters including spaces. Maximum length: - no more than
                                23,000 characters including spaces.
                            </p>
                            <p>At the same time, the volume of the article in A4 sheets: from 6 to 12</p>
                            <p>
                                (design: TimesNewRoman font, 14 point normal spacing between letters, line spacing
                                min.18; margins 2 cm on all sides).
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Introduction and keywords</p>
                        </th>
                        <td>
                            <p>Before the title of the article, indicate the UDC code.</p>
                            <p>
                                250-300 words (as a rule, these are 6-8 sentences), should meaningfully convey the main
                                results in Russian and English.
                            </p>
                            <p>
                                The abstract should not be italicized, underlined, etc. Citations are not allowed in the
                                abstract.
                            </p>
                            <p>
                                Abbreviations must be spelled out. The abstract should not repeat the text of the
                                article.
                            </p>
                            <p>
                                Immediately after the abstract, keywords should be presented, which can consist of
                                individual words and phrases in Russian and English (up to 10 keywords).
                            </p>
                            <p>Italic or bold text is not required.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Requirements for the content of the article and its design</p>
                        </th>
                        <td>
                            <p>
                                After the keywords, you need to submit the text of the article itself. The text should
                                be divided into subchapters or
                            </p>
                            <p>follow this logical structure when writing:</p>
                            <p>introduction;</p>
                            <p>· methodology;</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; main part;</p>
                            <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; conclusions;</p>
                            <p>
                                Acknowledgments (i.e. this section is needed if it is necessary to indicate that the
                                article was prepared as part of a grant);
                            </p>
                            <p>
                                · thank colleagues who are not the authors of the article, but with their assistance the
                                study was conducted, etc.);
                            </p>
                            <p>Literature</p>
                            <p>Font size and line spacing should be the same throughout the text.</p>
                            <p>Paragraphs ("red line") should be set automatically, and not using the "space" key.</p>
                            <p>Double or triple spaces are not allowed.</p>
                            <p>When using abbreviations for the first time, be sure to indicate their decoding.</p>
                            <p>Direct speech, quoting should be framed using quotation marks "….."</p>
                            <p>It is NOT ALLOWED to use quotes of this format: “…..”.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Requirements for tables, figures and formulas</p>
                        </th>
                        <td>
                            <p>Before tables, figures and formulas there should be links to them.</p>
                            <p>
                                The text may contain tables, the titles of which should be given above the table with
                                center alignment.
                            </p>
                            <p>Text in tables: single spacing, font 12 Times New Roman.</p>
                            <p>
                                All components of the formulas must be formatted in the macro "Microsoft Equation" (Word
                                program).
                            </p>
                            <p>Graphics must be of good quality. Figure captions must be clearly displayed.</p>
                            <p>
                                Charts should include numeric values ​​and column names and should be easy to
                                understand.
                            </p>
                            <p>Figure captions are given under the figure, centered.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Requirements for sources used in the article</p>
                        </th>
                        <td>
                            <p>The bibliography should contain at least 10 and no more</p>
                            <p>15 sources. At least 2-3 sources are works published in the last 5-10 years.</p>
                            <p>
                                It is recommended that at least 50% of the sources included in the bibliographic list be
                                works
                            </p>
                            <p>published in English.</p>
                            <p>No more than three links to own publications are allowed.</p>
                            <p>Subscript links are not allowed.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Registration of references and list of references</p>
                        </th>
                        <td>
                            <p>– Links in the text are in square brackets.</p>
                            <p>– Sources are listed in order of citation in the text.</p>
                            <p>- All sources from the list of references should be referenced in the text.</p>
                            <p>
                                - The list of references is drawn up in accordance with the sample (see the section
                                "Model of Literature Design").
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>
                                Sample literature design (if the article is provided in Russian for subsequent
                                translation, links can be indicated in accordance with GOST R 7.0.100-2018
                            </p>
                        </th>
                        <td>
                            <p>Journal article, conference proceedings (printed)</p>
                            <p>
                                Bakhvalov, Yu. N. Training and evaluation of the generalizing ability of
                                multidimensional interpolation methods / Yu. N. Bakhvalov, IV Kopylov // Computer
                                Research and Modeling. - 2015. - Volume 7. - No. 5. - P. 1023–1031.
                            </p>
                            <p>Journal article (electronic)</p>
                            <p>
                                Kontorovich, A. E. Forecast of global energy supply: methodology, quantitative
                                estimates, practical conclusions / A. E. Kontorovich, A. G. Korzhubaev, L. V. Eder. –
                                DOI 10.21267/IN.2016.6.2294. – Text: electronic // Mineral Resources of Russia.
                                Economics and Management. - 2006. - No. 5. - P. 6–15. –URL:{" "}
                                <a href="http://www.vipstd.ru/gim/contant/view/90/278/">
                                    http://www.vipstd.ru/gim/contant/view/90/278/
                                </a>
                                . (date of access: 05/22/2020).
                            </p>
                            <p>Book</p>
                            <p>
                                Semenov, V. V. Philosophy: the result of millennia. Philosophical psychology / VV
                                Semenov; Russian Academy of Sciences, Pushchino Research Center, Institute of Cell
                                Biophysics, Academy of Life Preservation Problems. Pushchino: PNTs RAS, 2000. - 64 p.
                            </p>
                            <p>
                                Myocarditis: modern approaches to diagnosis and treatment: monograph / V. N. Bykov [and
                                others]; Managing editor VN Sukhov; Ministry of Education of the Russian Federation, St.
                                Petersburg State Forest Engineering Academy. – [2nd ed., revised. and add.]. St.
                                Petersburg: SPbLTA, 2001. - 231 p.
                            </p>
                            <p>Thesis</p>
                            <p>
                                Mindiashvili, D. G. Controlling the process of formation of statokinetic stability of
                                qualified wrestlers: specialty 13.00.04 "Theory and methods of physical education,
                                sports training, health-improving and adaptive physical culture": dissertation for the
                                degree of candidate of pedagogical sciences / Mindiashvili Dmitry Georgievich;
                                Krasnoyarsk State Pedagogical University. - Krasnoyarsk, 1992. - 24 p.
                            </p>
                            <p>Internet resource</p>
                            <p>
                                Government of the Russian Federation: official site. – URL:{" "}
                                <a href="http://www.government.ru">www.government.ru</a> (date of access: 02/19/2018). –
                                Text: electronic.
                            </p>
                            <p>
                                Please note that the hyperlink must be "inactive", http:// - do not specify this
                                element.
                            </p>
                            <p>official source</p>
                            <p>
                                Russian Federation. Laws. On military duty and military service: Federal Law No. 142 of
                                March 6, 1998 (as amended on January 8, 2020). – URL:{" "}
                                <a href="http://www.docs.cntd.ru/document/901704754">
                                    www.docs.cntd.ru/document/901704754
                                </a>
                                (date of access: 05/22/2020). – Text: electronic.
                            </p>
                            <p>
                                GOST R 57564–2017. Organization and implementation of work on international
                                standardization in the Russian Federation = Organization and implementation of activity
                                on international standardization in Russian Federation: national standard of the Russian
                                Federation: official edition: approved and put into effect by Order of the Federal
                                Agency for Technical Regulation and Metrology of July 28, 2017 No. 767-st: introduced
                                for the first time: introduction date 2017-12-01 / developed by the All-Russian Research
                                Institute for Standardization and Certification in Mechanical Engineering (VNIINMASH). -
                                Moscow: Standartinform, 2017. - 43 p.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p>Information about the author(s)</p>
                        </th>
                        <td>
                            <p>The title of the scientific article is followed by:</p>
                            <p>– Full name of the author (in Russian and English);</p>
                            <p>
                                – academic degree and title, position, place of work (in Russian and English); specify
                                ORCID;
                            </p>
                            <p>- postal address of the organization, including street, house, settlement index;</p>
                            <p>– contact phone number of the author;</p>
                            <p>In the letter itself, indicate the e-mail and contact phone number for communication.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    ),
    cn: "",
};

export const ABOUT_EXPO = {
    ru: (
        <div>
            <p>
                В период проведения Международного форума Kazan Digital Week – 2022 на экспозиционной площадке МВЦ
                «Казань Экспо» будет проходить выставка высокотехнологических разработок, программного обеспечения,
                программно-аппаратных комплексов, действующих образцов техники и оборудования.
            </p>
            <p>
                На площадке выставки предоставляется возможность проведения презентаций и участия в переговорах в очном
                и онлайн форматах.
            </p>
            <p>На 10 000 кв.м. выставочной площади свои разработки продемонстрируют более 200 компаний.</p>
            <p>Выставочная экспозиция Форума условно разделена на 10 тематических направлений:</p>
            – Интеллектуальные транспортные системы; <br />
            – Цифровые технологии в сфере государственного управления; <br />
            – Цифровая индустрия 4.0; <br />
            – Кибербезопасность нового времени; <br />
            – Экосистема финтех; <br />
            – Инновации, интегрированные в бизнес; <br />
            – Цифровые технологии в здравоохранении и медицине; <br />
            – Цифровые технологии в образовании; <br />
            – Цифровые технологии в культуре; <br />
            – Цифровые технологии в сфере сельского хозяйства. <br />
            Организатор выставочной экспозиции – Ассоциация содействия цифровому развитию.
            {/*<p></p>*/}
            {/*<p>*/}
            {/*    Менеджер выставочной экспозиции:&nbsp;*/}
            {/*    <br />*/}
            {/*    Гильмутдинова Алсу Азатовна&nbsp;*/}
            {/*    <br />*/}
            {/*    эл.почта: <a href="mailto:Alsu.Gilmutdinova@tatar.ru">Alsu.Gilmutdinova@tatar.ru</a>*/}
            {/*    <br />*/}
            {/*    телефон: +7 (987) 215-11-00*/}
            {/*</p>*/}
        </div>
    ),
    en: (
        <div>
            <p>
                During the International Forum Kazan Digital Week - 2022, the exposition site of Kazan Expo will host an
                exhibition of high-tech developments, software, software and hardware systems, existing models of
                machinery and equipment.
            </p>
            <p>
                The exhibition site provides an opportunity for presentations and participation in negotiations in
                person and online formats.
            </p>
            <p>For 10,000 sq.m. more than 200 companies will demonstrate their developments on the exhibition area.</p>
            <p>The exhibition exposition of the Forum is conditionally divided into 10 thematic areas:</p>
            <p>– Intelligent transport systems;</p>
            <p>– Digital technologies in public administration;</p>
            <p>– Digital Industry 4.0;</p>
            <p>– New Age Cybersecurity;</p>
            <p>– Fintech Ecosystem;</p>
            <p>– Business-integrated innovations;</p>
            <p>– Digital technologies in education;</p>
            <p>– Digital technologies in culture;</p>
            <p>– Digital technologies in healthcare and medicine;</p>
            <p>– Digital technologies in agriculture.</p>
            <p>
                The organizer of the exhibition exposition is the Association for the Promotion of Digital Development.
            </p>
            {/*<p>*/}
            {/*    Exhibition manager:*/}
            {/*    <br />*/}
            {/*    Alsu Gilmutdinova*/}
            {/*    <br />*/}
            {/*    email:&nbsp;<a href="mailto:Alsu.Gilmutdinova@tatar.ru">Alsu.Gilmutdinova@tatar.ru</a>*/}
            {/*    <br />*/}
            {/*    tel.: +7 (987) 215-11-00*/}
            {/*</p>*/}
        </div>
    ),
    cn: "",
};
export const VISIT_EXPO = {
    ru: (
        <div>
            <p>Уважаемые участники!</p>
            <p>
                Для получения доступа на выставочную программу Международного форума «Kazan Digital Week -2022» –
                пожалуйста, зарегистрируйтесь в личном кабинете участника и выберите соответствующую категорию.
                Посещение выставочной экспозиции – бесплатно.
            </p>
            <p>
                ВАЖНО: Доступ в Международный выставочный центр «Казань Экспо» разрешается только при наличии защитных
                масок.
            </p>
            <p>
                Для участия в деловой программе необходимо&nbsp;приобрести пакет с соответствующей опцией в личном
                кабинете.
            </p>
            <p></p>
            <p>
                <b>Место проведения:</b>
            </p>
            <p>
                <b></b>
                Международный выставочный центр «Казань Экспо», павильон №2 (план МВЦ «Казань Экспо») <br />
                Адрес: 422623, Республика Татарстан, Лаишевский муниципальный район, с. Большие Кабаны, <br />
                ул. Выставочная, 1&nbsp;
            </p>
            <p>Как добраться:&nbsp;</p>
            <p>
                От международного аэропорта города Казани: <br />
                - крытый пешеходный переход к Международному аэропорту «Казань», 5 мин. пешком) <br />
                Личный транспорт:
            </p>
            <p>-&nbsp;автомобиль (20 мин от города Казани)</p>
            <p>
                Общественный транспорт: <br />
                - автобус №197 до остановки «Аэропорт» <br />
                Аэроэкспресс: <br />- аэроэкспресс (28 мин из центра города Казани, 5 остановок)
            </p>
            <p>
                &nbsp; <br />
                <b>Расписание электричек на сайте:</b> <br />
                <a href="site/common.tsx">http://www.kazan.aero/for-passengers-and-guests/schedule-aeroexpress/</a>
            </p>
            <p>
                <b>Время работы выставки:</b>
            </p>
            <p>21–23 сентября 2022 года, 09:00–18:00</p>
            <br />
            <p></p>
        </div>
    ),
    en: (
        <div>
            <p>Dear participants!</p>
            <p>
                To gain access to the exhibition program of the Kazan Digital Week -2022 International Forum, please
                register in the participant's personal account and select the appropriate category. Visiting the
                exhibition exposition is free of charge.
            </p>
            <p>
                IMPORTANT: Access to the Kazan Expo International Exhibition Center is allowed only with protective
                masks.
            </p>
            <p>
                To participate in the business program, you need to purchase a package with the appropriate option in
                your account.
            </p>
            <p>Location:</p>
            <p>
                International Exhibition Center "Kazan Expo", Pavilion No. 2 (Международный выставочный центр «Казань
                Экспо», павильон №2»)
                <br />
                <br />
            </p>
            <p>
                Address: 422623, Republic of Tatarstan, Laishevsky municipal district, Bolshye Kabani, Vistavochnaya
                St., 1&nbsp;(Адрес: 422623, Республика Татарстан, Лаишевский муниципальный район, с. Большие Кабаны, ул.
                Выставочная, 1)
            </p>
            <p>How to get there:&nbsp;</p>
            <p>
                From Kazan International Airport:
                <br />- covered pedestrian crossing to Kazan International Airport (5 min. on foot)
            </p>
            <p>By car:</p>
            <p>-&nbsp;20 minutes from the city of Kazan</p>
            <p>
                Public transport:
                <br />
                - bus number 197 to the stop "Airport"
                <br />
                Aeroexpress:
                <br />- 28 min from Kazan city center, 5 stops
            </p>
            <p>
                &nbsp;
                <br />
                Train timetable on the website:
                <br />
                <a href="http://www.kazan.aero/for-passengers-and-guests/schedule-aeroexpress/">
                    http://www.kazan.aero/for-passengers-and-guests/schedule-aeroexpress/
                </a>
            </p>
            <p>Exhibition opening hours:</p>
            <p>September 21-23, 2022, 09:00-18:00</p>
        </div>
    ),
    cn: "",
};

export const PARTICIPATION_EXPO = {
    ru: (
        <p>
            <a href="/upload/doc/exhibition_solutions.pdf" target="_blank">
                Готовые выставочные решения
            </a>
            <br />
            <br />
            <a href="/upload/doc/partner_packages.pdf" target="_blank">
                Партнерские пакеты
            </a>
            <br />
            <br />
            <p>
                Условия включают общую информацию о выставке и формы заявок на предлагаемые выставочные услуги, документ
                Руководство Участника направляется после заполнения Формы на бронирование выставочного стенда.
                Внимательное изучение содержания Условий позволит Вам решить все вопросы организации и проведения
                выставки. Просим Вас сохранить копии заполненных форм, чтобы возникшие вопросы могли быть улажены
                быстро, а выставленные счета на услуги соответствовали предъявляемым формам.
                <br />
            </p>
            <p>Пожалуйста, обратите внимание!</p>
            <p>
                Просим соблюдать указанные сроки подачи заявок для того, чтобы мы смогли качественно и своевременно
                выполнить Ваши заказы. В противном случае мы не сможем гарантировать их выполнение в срок.
            </p>
            <p>Документы:</p>
            <p></p>
            <p>
                <a href="/upload/doc/form/installation-plan.pdf" target="_blank">
                    Форма 1 – Монтажный план выставочного стенда
                </a>
            </p>
            <p>
                <a href="/upload/doc/form/form6.docx" target="_blank">
                    {" "}
                    Форма 6 – Заявка на ввоз оборудования
                </a>
            </p>
            <p>
                <a href="/upload/doc/form/form6а.docx" target="_blank">
                    {" "}
                    Форма 6а – Заявка на вывоз оборудования&nbsp;
                </a>
            </p>
            <p>
                <a href="/upload/doc/form/form10.docx" target="_blank">
                    {" "}
                    Форма 10 – Заявка на вход в период монтажа / демонтажа&nbsp;
                </a>
            </p>
            <p>
                <a href="/upload/doc/form/form12.1.docx" target="_blank">
                    {" "}
                    Форма 12/1 – Форма для размещения информации в официальный каталог&nbsp;
                </a>
            </p>
            <p>
                <a href="/upload/doc/form/form13.docx" target="_blank">
                    {" "}
                    Форма 13 – Форма для наполнения интерактивного стенда информацией об участнике&nbsp;
                </a>
            </p>
            <p>Контакты</p>
            <p>
                Заполненные формы необходимо отправить в адрес менеджера по выставочной экспозиции Форума:&nbsp;&nbsp;
                <br />
                Гильмутдинова Алсу <br />
                эл.почта: <a href="mailto:Alsu.Gilmutdinova@tatar.ru">Alsu.Gilmutdinova@tatar.ru</a> <br />
                телефон: +7 (987) 215-11-00
            </p>
            <br />
        </p>
    ),
    en: (
        <div>
            <p>
                The conditions include general information about the exhibition and application forms for the offered
                exhibition services, the Exhibitor Guide document is sent after filling out the Exhibition Stand Booking
                Form. A careful study of the content of the Conditions will allow you to resolve all issues of
                organizing and holding the exhibition. We kindly ask you to keep copies of the completed forms so that
                any questions that arise can be resolved quickly, and invoices for services correspond to the submitted
                forms.Пожалуйста, обратите внимание!
            </p>
            <p>
                We ask you to observe the indicated deadlines for submitting applications so that we can fulfill your
                orders in a quality and timely manner. Otherwise, we will not be able to guarantee their completion on
                time.
            </p>
            <p></p>
            <p>Contact information</p>
            <p>
                The completed forms must be sent to the address of the Forum exposition manager:&nbsp;&nbsp;
                <br />
                Alsu Gilmutdinova
                <br />
                email:&nbsp;<a href="mailto:Alsu.Gilmutdinova@tatar.ru">Alsu.Gilmutdinova@tatar.ru</a>
                <br />
                tel.: +7 (987) 215-11-00
            </p>
            <br />
            <p></p>
        </div>
    ),
    cn: "",
};

export const DATE_DESC = {
    publication: "Дата публикации",
    techSession: "Дата проведения",
};

export const EXPONENTS_TYPE = {
    exponents: 0,
    organizers: 1,
    gen_partner: 2,
    partners: 3,
    info_partners: 4,
    support: 5,
    official_partners: 6,
    online_partners: 7,
    gen_hr_partner: 8,
    industry_partners: 9,
    gen_info_partners: 10,
    official_auto_partner: 11,
    business_program_partner: 12,
    // Это я от балды написал, в константах его еще нету
    finance_partner: 13,
    official_media_partner: 14,
};

export const EXPONENTS_STATE = {
    created: 0,
    published: 1,
    archive: 2,
};

export const BOOK_LINK = {
    desc: {
        ru: "Скачать",
        en: "Download",
        cn: "",
    },
    link: {
        ru: "/upload/doc/Sbornik_2022.pdf",
        en: "/upload/doc/Sbornik_2022.pdf",
        cn: "",
    },
};

export const BOOK_TEXT = {
    desc: {
        ru: (
            <div>
                <p>
                    В сборник трудов форума включены концептуальные материалы по целям и задачам системы
                    государственного управления, науки, бизнеса и социальных институтов российских регионов в
                    современной геополитической обстановке, прошедшие экспертную верификацию статьи и отчеты по
                    результатам научных исследований, прикладным цифровым решениям и инженерным разработкам,
                    долгосрочным финансово-технологическим проектам, выполняемым в соответствии со Стратегией и
                    Программой научно-технологического развития.
                </p>
                <p>
                    Сборник трудов в силу новизны и актуальности контента представляет практический интерес для широкого
                    круга разработчиков и пользователей комплексных цифровых решений, специализированных
                    программно-аппаратных комплексов автоматизации бизнес-процессов, составителей учебных программ
                    цифровых кафедр образовательных учреждений и цифровых подразделений предприятий и учреждений.
                </p>
            </div>
        ),
        en: (
            <div>
                <p>
                    The collection of forum proceedings includes conceptual materials on the goals and objectives of the
                    public administration system, science, business and social institutions of the Russian regions in
                    the current geopolitical situation, peer-reviewed articles and reports on the results of scientific
                    research, applied digital solutions and engineering developments, long-term financial and
                    technological projects carried out in accordance with the Strategy and Program of Scientific and
                    Technological Development.
                </p>
                <p>
                    The collection of works, due to the novelty and relevance of the content, is of practical interest
                    to a wide range of developers and users of integrated digital solutions, specialized software and
                    hardware systems for automating business processes, curators of digital departments of educational
                    institutions and digital departments of enterprises and institutions.
                </p>
            </div>
        ),
        cn: "",
    },
};

export const SEPTEMBER_DESC = {
    ru: "c 17 по 19 cентября",
    en: "from 17 to 19 september",
    cn: "",
};

export const INVITE_DESC = {
    ru: "Предлагаем ознакомиться",
    en: "For review",
    cn: "",
};

export const FORUM_DESC = {
    ru: "форума",
    en: "forum",
    cn: "",
};

export const ANONS_DESC = {
    main: { ru: "Анонсы мероприятий", en: "Event announcements", cn: "" },
    time: { ru: "Время", en: "Time", cn: "" },
    date: { ru: "Дата", en: "Date", cn: "" },
};
