import { ReactComponent as UnionIcon1 } from "../../components/Site/Exhibition/img/union_1.svg";
import { ReactComponent as UnionIcon2 } from "../../components/Site/Exhibition/img/union_2.svg";
import { ReactComponent as UnionIcon3 } from "../../components/Site/Exhibition/img/union_3.svg";
import { ReactComponent as PhoneSVG } from "../../styles/img/icon/ContactIcons/phone.svg";
import { ReactComponent as MailSVG } from "../../styles/img/icon/common/mail.svg";
import React from "react";

import Econom1_6 from "../../components/Site/Exhibition/img/stands/econom1-6.png";
import Econom1_9 from "../../components/Site/Exhibition/img/stands/econom1-9.png";
import Econom2_6 from "../../components/Site/Exhibition/img/stands/econom2-6.png";
import Econom2_9 from "../../components/Site/Exhibition/img/stands/econom2-9.png";
import Standard1_6 from "../../components/Site/Exhibition/img/stands/standart1-6.png";
import Standard1_9 from "../../components/Site/Exhibition/img/stands/standart1-9.png";
import Standard2_6 from "../../components/Site/Exhibition/img/stands/standart2-6.png";
import Standard2_9 from "../../components/Site/Exhibition/img/stands/standart2-9.png";
import Standard3_6 from "../../components/Site/Exhibition/img/stands/standart3-6.png";
import Standard3_9 from "../../components/Site/Exhibition/img/stands/standart3-9.png";
import Standard1_9u from "../../components/Site/Exhibition/img/stands/standart1-9u.png";
import Standard2_9u from "../../components/Site/Exhibition/img/stands/standart2-9u.png";
import Standard3_9u from "../../components/Site/Exhibition/img/stands/standart3-9u.png";
import { CONTACT_LINKS } from "./contactsCache";

export const EXHIBITION_INFO = {
    header: {
        ru: (
            <span>
                Приглашаем посетить выставочную экспозицию <br /> Форума Kazan Digital Week 2025
            </span>
        ),
        en: (
            <span>
                We invite you to visit the exhibition exposition <br /> of the Forum Kazan Digital Week 2025
            </span>
        ),
        cn: "",
    },
    header_additional: {
        ru: "Преимущества участия",
        en: "Participation Benefits",
        cn: "",
    },
    stand_header: {
        ru: "Варианты стендов для участия в выставке",
        en: "Exhibition stand options",
        cn: "",
    },
    stand_cost: {
        ru: "Цена",
        en: "Cost",
        cn: "",
    },
    stand_rubbles: {
        ru: "руб.",
        en: "rub.",
        cn: "",
    },
    stand_area: {
        ru: "Размер стенда",
        en: "Stand area",
        cn: "",
    },
    stand_options: {
        ru: "Комплектация стенда",
        en: "Stand options",
        cn: "",
    },
};

export const EXHIBITION_PHOTO_CARDS = [
    {
        file: <UnionIcon1 />,
        name: {
            ru: "Установить новые \nделовые контакты",
            en: "Establishing new business contacts",
            cn: "",
        },
    },
    {
        file: <UnionIcon2 />,
        name: {
            ru: "Продвижение \nсвоих продуктов",
            en: "Promoting your products",
            cn: "",
        },
    },
    {
        file: <UnionIcon3 />,
        name: {
            ru: "Заявить о себе \nна международной площадке",
            en: "Making yourself known on the international platform",
            cn: "",
        },
    },
];
export const EXHIBITION_CONTACTS = [
    {
        icon: <PhoneSVG />,
        contact: CONTACT_LINKS.expo.tel,
        prefix: "tel",
    },
    {
        icon: <MailSVG />,
        contact: CONTACT_LINKS.expo.mail,
        prefix: "mailto",
    },
];

export const EXHIBITION_LEARN_MORE = {
    ru: (
        <div>
            <span>Срок подачи заявки на участие в выставочной экспозиции до 25 августа 2025 года</span>
            {/*<br />*/}
            {/*<span>*/}
            {/*    <b>После 27 августа 2023 года и до 3 сентября 2023 года участие возможно при 50% наценке</b> на все*/}
            {/*    позиции, при наличии свободных выставочных мест.*/}
            {/*    <br />*/}
            {/*    <b>После 3 сентября участие возможно при 100% наценке на все позиции, </b>*/}
            {/*    при наличии свободных выставочных мест.*/}
            {/*</span>*/}
        </div>
    ),
    en: (
        <div>
            <span>The deadline for applying for participation in the exhibition is until August 25, 2025</span>{" "}
            {/*<span>*/}
            {/*    Applications <b>after August 27, 2023 and until September 3, 2023 are possible with a 50% mark-up </b>{" "}*/}
            {/*    for all positions and are subject to the availability of exhibition space.*/}
            {/*    <br />*/}
            {/*    Applications <b>after September 3 are possible with a 100% mark-up </b>*/}
            {/*    for all positions and are subject to the availability of exhibition space.*/}
            {/*</span>*/}
        </div>
    ),
    cn: <span />,
};

export const EXHIBITION_QUESTION = {
    ru: "Есть вопросы по участию в выставке?",
    en: "Got any questions about being an exhibitor?",
    cn: "",
};

export const EXHIBITION_AWARDS = {
    desc: {
        ru: 'Информация о конкурсе "ИТ Лидер"',
        en: 'Information about the competition "IT Leader"',
        cn: "",
    },
    presentation_link: {
        ru: "/upload/doc/presentation_IT_leader.pdf",
        en: "",
        cn: "",
    },
    awards_name: {
        ru: "ИТ Лидер",
        en: "IT Leader",
        cn: "",
    },
};

export const EXHIBITION_ADDITIONAL = {
    solutions_desc: {
        ru: "Готовые выставочные решения",
        en: "Ready-made exhibition solutions",
        cn: "",
    },
    expo_desc: {
        ru: "Экспоплан",
        en: "Exhibition plan",
        cn: "",
    },
    expo_download_desc: {
        ru: "Посмотреть экспоплан",
        en: "See the expo",
        cn: "",
    },
    expo_download_link: {
        ru: "https://drive.google.com/file/d/1diBkz0JgW-AfUZs499v-Wo7UTOO77TbB/view",
        en: "https://drive.google.com/file/d/1diBkz0JgW-AfUZs499v-Wo7UTOO77TbB/view",
        cn: "",
    },
    expo_more_info: {
        ru: "/upload/doc/presentation_expo.pdf",
        en: "/upload/doc/presentation_expo.pdf",
        cn: "",
    },
    partners_more_info: {
        ru: "/upload/doc/presentation_partners.pdf",
        en: "/upload/doc/presentation_partners.pdf",
        cn: "",
    },
    last_year_expo_desc: {
        ru: "Выставка прошлых лет",
        en: "Last years’ exhibition",
        cn: "",
    },
};

export const EXHIBITION_STANDS = [
    {
        id: 0,
        name: {
            ru: "Эконом 1",
            en: "Economy 1",
            cn: "",
        },
        price: ["50 200", "70 000"],
        areas: ["6", "9"],
        desc: {
            ru: (
                <ul>
                    <li>конструктив стенда Octanorm, ковровое покрытие (цвет: серый)</li>
                    <li>1 стол, 2 стула ISO, корзина для мусора, 1 светильник-прожектор</li>
                    <li>подключение к сети 220В - 1 шт (тройник до 3кВт)</li>
                    <li>нанесение фризовой надписи (15 знаков) - 1 шт (цвет: зеленый)</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Econom1_6, Econom1_9],
    },
    {
        id: 1,
        name: {
            ru: "Эконом 2",
            en: "Economy 2",
            cn: "",
        },
        price: ["70 900", "90 800"],
        areas: ["6", "9"],
        desc: {
            ru: (
                <ul>
                    <li>конструктив стенда Octanorm, ковровое покрытие (цвет: серый)</li>
                    <li>1 стол, 2 стула ISO, корзина для мусора, 1 светильник-прожектор</li>
                    <li>подключение к сети 220В - 1 шт (тройник до 3кВт)</li>
                    <li>нанесение фризовой надписи (15 знаков) - 1 шт (цвет: зеленый)</li>
                    <li>плазменная панель 50" - 1 шт с подключением</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Econom2_6, Econom2_9],
    },
    {
        id: 2,
        name: {
            ru: "Стандарт 1",
            en: "Standard 1",
            cn: "",
        },
        price: ["105 720", "135 900"],
        areas: ["6", "9"],
        desc: {
            ru: (
                <ul>
                    <li>конструктив стенда Octanorm, ковровое покрытие (цвет: серый)</li>
                    <li>1 стол, 2 кресла с подлокотниками, корзина для мусора, 1 светильник-прожектор</li>
                    <li>подключение к сети 220В - 1 шт (тройник до 3кВт)</li>
                    <li>оклейка стенда пленкой Orajet**</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard1_6, Standard1_9],
    },
    {
        id: 3,
        name: {
            ru: "Стандарт 2",
            en: "Standard 2",
            cn: "",
        },
        price: ["127 600", "152 950"],
        areas: ["6", "9"],
        desc: {
            ru: (
                <ul>
                    <li>конструктив стенда Octanorm, ковровое покрытие (цвет: серый)</li>
                    <li>1 стол, 2 кресла с подлокотниками, корзина для мусора, 1 светильник-прожектор</li>
                    <li>подключение к сети 220В - 1 шт (тройник до 3кВт)</li>
                    <li>оклейка стенда пленкой Orajet**</li>
                    <li>плазменная панель 50" - 1 шт с подключением</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard2_6, Standard2_9],
    },
    {
        id: 4,
        name: {
            ru: "Стандарт 3",
            en: "Standard 3",
            cn: "",
        },
        price: ["140 450", "163 900"],
        areas: ["6", "9"],
        desc: {
            ru: (
                <ul>
                    <li>конструктив стенда Octanorm, ковровое покрытие (цвет: серый)</li>
                    <li>
                        1 стеклянный стол, 2 кресла с подлокотниками, корзина для мусора, 1 светильник-прожектор, 1
                        барный стул
                    </li>
                    <li>подключение к сети 220В - 1 шт (тройник до 3кВт)</li>
                    <li>оклейка стенда пленкой Orajet**</li>
                    <li>плазменная панель 50" - 1 шт с подключением</li>
                    <li>информационная стойка - 1 шт с оклейкой с 3-х сторон</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard3_6, Standard3_9],
    },
    {
        id: 5,
        name: {
            ru: "Улучшенный Стандарт 1",
            en: "Superior Standard 1",
            cn: "",
        },
        price: ["162 000", "18 000"],
        areas: ["9", "1"],
        desc: {
            ru: (
                <ul>
                    <li>Конструктив стенда с брендированием* (3 x 3 x 0,5 метров)</li>
                    <li>Ковровое покрытие (цвет: серый)</li>
                    <li>Светильник-прожектор выносной на штанге - 2 шт.</li>
                    <li>Стол стеклянный Гидра - 1 шт.</li>
                    <li>Кресло (экокожа) - 2 шт.</li>
                    <li>Корзина для мусора - 1 шт.</li>
                    <li>Подключение к сети 220В - 1 шт. (тройник до 3 кВт)</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard1_9u],
    },
    {
        id: 6,
        name: {
            ru: "Улучшенный Стандарт 2",
            en: "Superior Standard 2",
            cn: "",
        },
        price: ["180 000", "20 000"],
        areas: ["9", "1"],
        desc: {
            ru: (
                <ul>
                    <li>Конструктив стенда с брендированием* (3 x 3 x 0,5 метров)</li>
                    <li>Ковровое покрытие (цвет: серый)</li>
                    <li>Светильник-прожектор выносной на штанге - 2 шт.</li>
                    <li>Стол стеклянный Гидра - 1 шт.</li>
                    <li>Кресло (экокожа) - 2 шт.</li>
                    <li>Стул барный - 1 шт.</li>
                    <li>Информационная стойка - 1 шт. с оклейкой* с 3 сторон</li>
                    <li>Стойка для рекламных материалов - 1 шт. </li>
                    <li>Корзина для мусора - 1 шт.</li>
                    <li>Подключение к сети 220В - 1 шт. (тройник до 3 кВт)</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard2_9u],
    },
    {
        id: 7,
        name: {
            ru: "Улучшенный Стандарт 3",
            en: "Superior Standard 3",
            cn: "",
        },
        price: ["234 000", "26 000"],
        areas: ["9", "1"],
        desc: {
            ru: (
                <ul>
                    <li>Конструктив стенда с брендированием* (3 x 3 x 0,5 метров)</li>
                    <li>Ковровое покрытие (цвет: серый)</li>
                    <li>Светильник-прожектор выносной на штанге - 2 шт.</li>
                    <li>Стол стеклянный Гидра - 1 шт.</li>
                    <li>Кресло (экокожа) - 2 шт.</li>
                    <li>Стул барный - 1 шт.</li>
                    <li>Информационная стойка - 1 шт. с оклейкой* с 3 сторон</li>
                    <li>Стойка для рекламных материалов - 1 шт. </li>
                    <li>плазменная панель 50" - 1 шт с подключением</li>
                    <li>Корзина для мусора - 1 шт.</li>
                    <li>Подключение к сети 220В - 1 шт. (тройник до 3 кВт)</li>
                </ul>
            ),
            en: "",
            cn: "",
        },
        photos: [Standard3_9u],
    },
];
