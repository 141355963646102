import React, {useEffect} from "react";
import { reselect_Menu_Status } from "../controllers/profile-route/redux-store/profile/profile";
import { makeStyles } from "@material-ui/core/styles";
import KDWSnackbar from "../components/KDWSnackbar/KDWSnackbar";
import { BaseResponseCondition } from "api/api";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import UserMenu from "./Account/UserMenu/UserMenu";
import UserLeftMenu from "./Account/UserLeftMenu/UserLeftMenu";
import Footer from "./Site/Footer/Footer";
import {useParams} from "react-router-dom";
import {doc} from "prettier";
import __translate from "../helper/locale/locale";
import __locale from "../helper/location/location";

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexWrap: "nowrap",
        position: "relative",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            paddingLeft: 0,
            paddingRight: 0,
        },
        background: "#f4f4f4",
        paddingLeft: 260,
        paddingRight: 0,
    },
    contentHideNenu: {
        paddingLeft: 80,
        [theme.breakpoints.down(958)]: {
            paddingLeft: 0,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "21px",
        padding: theme.spacing(8),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(6),
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        textTransform: "uppercase",
        marginTop: "1em",
        flexGrow: 1,
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            marginBottom: "1em !important",
        },
    },
    greenTitle: {
        color: "var(--kdwsite-color-green)",
        fontSize: "14px !important",
        fontWeight: "bold",
    },
    logo: {
        [theme.breakpoints.down(1200)]: {
            width: "200px",
            marginLeft: "1em !important",
        },
        [theme.breakpoints.down(958)]: {
            marginTop: "2em !important",
        },
    },
    navlogo: {
        marginRight: "2em",
        width: "125px",
        [theme.breakpoints.down(1200)]: {
            width: "125px",
            marginLeft: "1em !important",
        },
        [theme.breakpoints.down(958)]: {
            marginTop: "1em !important",
            marginBottom: "0",
        },
    },
    button: {
        marginLeft: theme.spacing(1),
        borderRadius: "12px",
        padding: "0.5em 4em",
    },
    btn_yellow: {
        color: "rgb(195, 191 ,33) !important",
        border: "1px solid rgba(195, 191,33,0.7) !important",
        // you'll probably want the hex color code below
        "&:hover": {
            backgroundColor: "rgba(255,251,62,0,3) !important",
            border: "1px solid rgb(195, 191, 33)",
        },
        // ... other css properties
    },
    input: {
        borderRadius: "12px !important",
    },
    select: {
        "& legend": {
            visibility: "visible",
        },
        "& legend span": {
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.54)",
        },
    },
    content: {
        transition: "all 0.5s ease",
        [theme.breakpoints.down(958)]: {
            padding: "0 1em",
        },
        minHeight: "calc(100vh - 65px)",

        ["& .MuiContainer-root"]: {
            padding: 20,
        },

        [theme.breakpoints.up(800)]: {
            ["& .MuiContainer-root"]: {
                margin: 0,
                paddingLeft:100,
                paddingRight: 40,
                paddingTop: 40,
                minWidth: "100%",
            }
        },
    },
    checkbox: {
        color: "#13C4A4 !important",
        ":checked": {
            color: "#13C4A4 !important",
            background: "#13C4A4",
        },
    },
    banner: {
        width: "100%",
        borderRadius: "10px",
        margin: "2em 0 1em",
    },
    activeLink: {
        background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
        ["-webkit-background-clip"]: "text",
        ["-webkit-text-fill-color"]: "transparent",
        backgroundClip: "text",
        textDecoration: "none",
        fontWeight: "bold",
        padding: "0em 0",
        display: "inline-block",
        whiteSpace: "nowrap",
        "& button:not(.custom) span::after": {
            content: "''",
            display: "block",
            height: 2,
            width: "100%",
            position: "relative",
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
        }

    },
    formPaper: {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "40px",
        marginTop:"20px",
    },
    formPaperForm: {
        paddingRight: "155px",
        paddingLeft: "155px",
        [theme.breakpoints.down(1600)]: {
            paddingRight: "60px",
            paddingLeft: "60px",
        },
        [theme.breakpoints.down(900)]: {
            paddingRight: "30px",
            paddingLeft: "30px",
        },
        display: "flex",
        flexDirection: "column",
        width: "initial",
        alignItems: "center",
        gap:"14px",
    },
    whiteChip: {
        background: "#FFFFFF",
        color: "#6F6F6F",
        borderRadius: "20px",
        padding: "5px 10px",
        marginLeft:"15px",
        textTransform: "lowercase",
    },
    hideMobile: {
        [theme.breakpoints.down(800)]: {
            display: "none !important",
        },
    },
    showMobile: {
        [theme.breakpoints.down(800)]: {
            display: "block !important",
        },
        [theme.breakpoints.up(800)]: {
            display: "none !important",
        },
    },
    grayBlockTitle: {
        fontWeight: 700,
        fontSize: "20px !important",
        lineHeight: "22px !important",
        color: "#6F6F6F",
        ["& > span" ]: {
            fontSize: "20px !important",
            lineHeight: "22px !important",
            fontWeight: 700,
        },
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
    },
    whiteCard: {
        background: "#fff",
        borderRadius: "10px",
        maxWidth: "calc(100% - 8px)",
        padding: "16px",
    },
    expoCard: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "& a" : {
            textDecoration: "none",
            height: "100%"
        },
        "& a > div:first-child": {
            background: "var(--kdwsite-color-white)",
            flexGrow: 1,
        },
        "&:hover": {
            "& > div": {
                boxShadow: "0 0 10px 0 rgba(111, 111, 111, 0.25);",
            }
        },
        borderRadius: "20px",
        boxShadow: "0 0 4px 0 rgba(111, 111, 111, 0.25)",
        overflow: "hidden",
    },
    expoVoteBtn: {
        borderRadius: "0",
        background: "var(--kdwsite-color-green)",
        padding: "16px 20px",
        boxShadow: "0 0 4px 0 rgba(111, 111, 111, 0.25)",
        display: "flex",
        gridGap: "6px",
        justifyContent: "center",
        alignItems: "center",
        width:"100%",
        color: "var(--kdwsite-color-white)",
        "&:hover": {
            background: "var(--kdwsite-color-green)",
        },
        "&.Mui-disabled" : {
            background: "var(--kdwsite-color-grey)",
            color: "white"
        }

    },
    expoVoteBtnCancel: {
        background: "var(--kdwsite-color-grey)",
        color: "var(--kdwsite-color-darkgrey)",
        "&:hover": {
            background: "var(--kdwsite-color-grey)",
        },
    },
    onlineCircle: {
        height: 10,
        width: 10,
        borderRadius: 5,
        display: "inline-block",
        position: "relative",
        background: "rgb(121, 232, 94)",
        top: -5
    },
}));
type Props = {
    children: React.ReactNode;
    condition?: BaseResponseCondition;
    onOpenSnackbar?: () => {};
    title?: string;
};
const KdwUserPage: React.FC<Props> = ({ children = null, condition, onOpenSnackbar = () => {}, title = null }) => {
    const classes = useStyles();
    const menuHide = useSelector(reselect_Menu_Status);
    const [isOpen, setOpen] = React.useState(condition?.notice.visibility || false);
    useEffect(() => setOpen(!!condition?.notice.visibility), [!!condition?.notice.visibility])
    const snackbar = {
        isOpen: isOpen,
        handleOpen: () => {setOpen(false);},
        severity: condition?.notice.severity || "info",
        message: condition?.response.message || "",
    }
    const params = useParams<{ ru: string }>();
    useEffect(() => {
        document.title = __translate("site.title", document.title);
    }, [__locale()]);
    return (
        <React.Fragment>
            <KDWSnackbar {...snackbar}/>
            <UserMenu />
            <Grid container className={classes.root + ` ${menuHide ? classes.contentHideNenu : ""}`}>
                <UserLeftMenu visibility={true} on_Close={() => {}} />
                <Grid className={classes.content} item xs={12}>
                    {title && (
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant="h2" className={classes.title}>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {children}
                    <Footer/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export default KdwUserPage;
