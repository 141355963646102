import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiRouteProfile, participationPayForm } from "controllers/profile-route/api/profile/profile";
import { Color } from "@material-ui/lab/Alert";
import {Company} from "../../../companies/redux-store/redux";
import {Startup} from "../../../startups/store";

export type TYPE_ARTICLE = {
    reason?: string;
    id: number;
    state: number;
    type: number;
    title: string;
    lang?: string;
    description: string;
    status: {
        state:number,
        message: string,
        message_file: {
            name: string;
            file: string;
        };
    };
    annotation_file: {
        name: string;
        file: string;
    };
    presentation_file: {
        name: string;
        file: string;
    };
    reason_file: {
        name: string;
        file: string;
    };
    events: Array<any>|null;
    forum_theme: number;
    user_id: number;
    created_at: number;
    updated_at: number;
    pay_order_id: string;
    user_event_id: string;
};
export type TYPE_PARTICIPATION = {
    id: number,
    user_id: number,
    role: number,
    roles: number,
    state_1c: number,
    name_en: string,
    surname_en: string,
    place_of_work: string,
    place_of_work_en: string,
    sector: number,
    work_position: string,
    work_position_en: string,
    online: boolean,
    created_at: number,
    updated_at: number,
    deleted_at: number,
    academic_degree: number,
    academic_degree_other: string,
    pay_order_id: number,
    org_type: string,
    work_type: number | null,
    status: {
        state: number,
        message: string,
        created_at: number
    },
    startup:Startup,
    reports: Array<TYPE_ARTICLE>;
    quest: Array<any> | null;
    articles: Array<TYPE_ARTICLE>;
    reports_rejected?: boolean | null;
};
export type TYPE_USER_PROFILE = {
    id: number;
    email: string;
    name: string;
    surname: string;
    lastname: string;
    phone: string;
    birthday: string;
    role: any;
    country: string;
    region: string;
    city: string;
    forum_themes: Array<any>;
    full_name: string;
    photo: any;
    source: string;
    accept: number|boolean;//одобрение участия админом
    accept_policy: boolean;
    subscribe: boolean;
    remember_token: any;
    created_at: number;
    updated_at: number;
    promo: string | null;
    pay_order_id: any;
    pay_status: number;
    doc_state: number;
    locale: string;
    events: Array<any>;
    password: string;
    covid_file: any;
    qr_token: any;
    covid_admin_action: boolean;
    covid_state: boolean;
    email_confirmed_at: boolean;
    password_confirmation: string;
    visits: Array<any>;
    votes: Array<any>;
    user_type: "user" | "admin" | "manager";
    participation_data: TYPE_PARTICIPATION
    moderator_events: any[];
    priority?: number;
    visit_id?: number;
    badge?: {
        color?: string;
        role?: string;
    };
    options?: {
        is_vip?: any;
        eat_type?: number;
        souvenir?: number;
        visit_date?: string;
        paper_badge?: boolean;
        manage_vips?: boolean;
        is_curator?: boolean;
        curator_role?: number;
        visit_id?: string|number;
        visits?: Array<string|number>;
        bio_id_2024?: string;
        target?: string;
        order?: string;
    };
    companies: Array<Company>;
    manage_roles?: [];
    manage_vips?: [];
    pay_order?: any;
    is_blocked?: boolean;
};

type Response = {
    inProgress: boolean;
    response: {
        data: any;
        result: boolean;
        errors: any;
        error: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerProfile = createSlice({
    name: "profile",
    initialState: {
        view: {} as Record<string, string>,
        entity: null as TYPE_USER_PROFILE,
        formHtml: null as string,
        formSign: "" as string,
        title: "Личные данные" as string,
        payOrder: null as any,
        menuStatus: false,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                error: {
                    code: 0,
                    message: "empty",
                },
                message: "",
            },
        } as Response,
    },
    reducers: {
        updateMenuStatus: (state, action) => {
            state.menuStatus = action.payload;
        },
        update: (state, action) => {
            state.view = action.payload;
        },

        on_Request: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "success",
                },
                inProgress: true,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                },
            };
        },
        onResponse: (state, action) => {
            // state.condition = {
            //     inProgress: false,
            //     response: action.payload,
            // };
        },
        on_Response_Success: (
            state,
            action: PayloadAction<{
                data: TYPE_USER_PROFILE;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            if (action.payload)
                state.condition = {
                    notice: {
                        visibility: true,
                        severity: "success",
                    },
                    inProgress: false,
                    response: {
                        result: action.payload.result,
                        data: action.payload.data,
                        errors: null,
                        error: {
                            code: action.payload.error.code,
                            message: action.payload.error.message,
                        },
                        message: action.payload.error.message,
                    },
                };
            state.entity = action.payload.data;
            localStorage.setItem("type", action.payload.data?.user_type || 'user');
        },
        on_Response_Failure: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    errors: action.payload.errors,
                    data: {},
                    error: {
                        code: action.payload.error.code,
                        message: action.payload.error.message,
                    },
                    message: action.payload.error.message,
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onFormResponse: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.formHtml = action.payload.html;
            state.payOrder = action.payload.order;
            state.formSign = "" + Math.ceil(new Date().getTime() / 60 / 1000) + action.payload.order?.report_id;
        },
        on_NoResponse: state => {
            state.condition.inProgress = false;
        },
        entityClear: state => {
            state.entity = null as TYPE_USER_PROFILE;
        },
        entityChange: (state, action) => {
            state.entity = {...state.entity, ...action.payload}
        },
        setProfileTitle:(state, action) => {
            state.title = action.payload
        }
    },
});

export const {
    update,
    on_Request,
    onResponse,
    on_Response_Success,
    on_Response_Failure,
    on_NoResponse,
    updateMenuStatus,
    onFormResponse,
    entityClear,
    setProfileTitle,
    entityChange,
} = slicerProfile.actions;

export const async_Profile = (callback?: (result: any) => void) => async (dispatch: any, getState: () => RootState) => {
    dispatch(on_Request());
    const result = await apiRouteProfile().catch(e => {
        console.error(e);
        dispatch(on_NoResponse());
    });
    if (result) {
        if (result.result) {
            dispatch(on_Response_Success(result));
        } else {
            dispatch(on_Response_Failure(result));
        }
    } else {
        dispatch(on_NoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncGetParticipationForm = (callback?: (result: any) => void, report_id?: any) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(on_Request());
    const sign = "" + Math.ceil(new Date().getTime() / 60 / 1000) + report_id; //Обновляем форму раз в минуту
    const request =  async () => await participationPayForm();
    const result = !getState().profile.formHtml || sign !== getState().profile.formSign ? await request() : null;
    if (result) {
        if (result.result) {
            dispatch(onFormResponse(result.data));
        } else {
            dispatch(on_Response_Failure(result));
        }
    } else {
        dispatch(on_NoResponse());
    }
    if (callback) {
        callback(result || { html: getState().profile.formHtml });
    }
};

export const reselect_Profile_Pay_Form = (state: RootState) => {
    return state.profile.formHtml;
};
export const reselect_Profile_Pay_Order = (state: RootState) => {
    return state.profile.payOrder;
};
export const reselect_Profile_entity = (state: RootState) => {
    return state.profile.entity;
};
export const reselect_Profile_title = (state: RootState) => {
    return state.profile.title;
};
export const reselect_Profile_condition = (state: RootState) => {
    return state.profile.condition;
};
export const reselect_Menu_Status = (state: RootState) => {
    return state.profile.menuStatus;
};

export default slicerProfile.reducer;
